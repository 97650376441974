export function useInfiniteFetch(queryKey, endpoint, extra) {
  const {
    public: { api_url },
  } = useRuntimeConfig();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: ({ pageParam }) =>
      $fetch(
        `${api_url}/${endpoint}?limit=30${
          pageParam ? `&cursor=${pageParam}` : ""
        }`,
        {
          credentials: "include",
        }
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage,
  });

  const results = computed(() =>
    data.value ? data.value.pages.flatMap((page) => page[extra]) : []
  );

  return {
    results,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isError,
    error,
  };
}
