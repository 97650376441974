<template>
  <UDashboardPage>
    <UDashboardPanel grow>
      <UDashboardNavbar title="Home">
        <template #right>
          <UTooltip text="Notifications" :shortcuts="['N']">
            <UButton
              color="gray"
              variant="ghost"
              square
              @click="isNotificationsSlideoverOpen = true"
            >
              <UChip color="red" inset>
                <UIcon name="i-heroicons-bell" class="w-5 h-5" />
              </UChip>
            </UButton>
          </UTooltip>

          <UDropdown :items="items">
            <UButton
              icon="i-heroicons-plus"
              size="md"
              class="ml-1.5 rounded-full"
            />
          </UDropdown>
        </template>
      </UDashboardNavbar>

      <UDashboardPanelContent>
        <!-- ~/components/home/HomeChart.vue -->

        <div class="lg:items-start gap-8 mt-8">
          <h2 class="mt-4 text-xl text-white font-bold flex items-center">
            replaca news
            <UIcon name="i-ri-arrow-right-s-line" class="w-8 h-8 ml-2" />
          </h2>
          <p class="mb-4">Guides, News, Tutorials and much more</p>

          <CardLatest :data="locations" :numSlides="6" v-slot="{ data }">
            <CardLocation :data="data" />
          </CardLatest>
          <h2 class="mt-4 text-xl text-white font-bold flex items-center">
            Most used locations
            <UIcon name="i-ri-arrow-right-s-line" class="w-8 h-8 ml-2" />
          </h2>
          <p class="mb-4">Guides, News, Tutorials and much more</p>
          <CardLatest :data="images" :numSlides="4" v-slot="{ data }">
            <DropBlockNew5
              :dataimage="data"
              :image="data.generated_image_url"
            />
          </CardLatest>
          <h2 class="mt-4 text-xl text-white font-bold flex items-center">
            Most seen reels
            <UIcon name="i-ri-arrow-right-s-line" class="w-8 h-8 ml-2" />
          </h2>
          <p class="mb-4">Guides, News, Tutorials and much more</p>
          <CardLatest :data="videos" :numSlides="4" v-slot="{ data }">
            <DropBlockNew5Video
              :dataimage="data"
              :image="data.base_image_url"
            />
          </CardLatest>
        </div>
      </UDashboardPanelContent>
    </UDashboardPanel>
  </UDashboardPage>
</template>

<script setup>
const { results: locations } = useInfiniteFetch(
  "locations",
  "locations/list",
  "locations"
);

const { results: images } = useInfiniteFetch(
  "gallery",
  "gallery/list",
  "images"
);

const { results: videos } = useInfiniteFetch("reel", "reel/list", "gallery");

/*const {
  public: { api_url },
} = useRuntimeConfig();

// Infinite query for fetching garments data
const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
  useInfiniteQuery({
    queryKey: ["locations"],
    queryFn: ({ pageParam }) =>
      $fetch(
        `${api_url}/locations/list?limit=30${
          pageParam ? `&cursor=${pageParam}` : ""
        }`,
        {
          credentials: "include",
        }
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage, // Use the `nextPage` cursor from the backend
    refetchOnMount: false,
  });

const locationsRef = computed(() =>
  data.value ? data.value.pages.flatMap((d) => d.locations) : []
);

const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
  useInfiniteQuery({
    queryKey: ["gallery"],
    queryFn: ({ pageParam }) =>
      $fetch(
        `${api_url}/gallery/list?limit=30${
          pageParam ? `&cursor=${pageParam}` : ""
        }`,
        {
          credentials: "include",
        }
      ),
    getNextPageParam: (lastPage) => lastPage.nextPage, // Use the `nextPage` cursor from the backend
  });

const images = computed(() =>
  data.value ? data.value.pages.flatMap((d) => d.images) : []
);*/
</script>
